@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'FONTSPRING DEMO - Garet';
  src: url('./fonts/GaretBold.woff2') format('woff2'),
    url('./fonts/GaretBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'FONTSPRING DEMO - Garet';
  font-weight: bold;
  color: #434343
}

h5 {
  font-size: 1.01rem;
  line-height: normal;
}

body {
  overflow-x: hidden;
}

.p-80 {
  padding: 60px 0;
}

button,
p,
a,
input,
li,
span,
label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #434343
}

.small-text {
  font-size: 14px;
}

p,
li {
  color: #434343;
  line-height: 1.6;
  font-size: 1.2rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.list-style-disc {
  list-style: disc;
  padding-left: 1rem;
  text-align: left;
}

.w-85 svg {
  width: 85%;
  margin-left: auto;
  display: flex;
}

a {
  text-decoration: none !important;
}

i {
  float: left;
  margin-top: 10px;
  color: #1c7c54;
}

button.btn.search:focus,
button.btn.search:hover {
  color: #fff;
}

.top-bar {
  background-color: #1c7c54;
}

.top-bar .nav-item a {
  min-width: 25px;
  height: 25px;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100%;
}

.nav-item a svg {
  fill: #1c7c54;
  margin: auto;
}

.green-text {
  color: #1c7c54;
  font-size: 1.4rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

.pawn {
  width: 25px;
  margin: auto;
}

.top-bar a,
.top-bar li {
  color: #fff;
  text-decoration: none;
  display: flex;
}

/* Topbar css */

nav .row {
  flex: 1;
}

.navbar-collapse {
  flex-basis: auto;
}

.navbar a {
  color: #434343;
}

.navbar {
  z-index: 99;
  position: relative;
}

.light-bg {
  background-color: #fffcf3;
}

.hamburger-toggle {
  display: flex;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.logo-wrap img {
  height: 55px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 10px 15px;
  text-align: left;
}

.gradient-border {
  background: linear-gradient(90deg, #e4811d 0%, #feedda 100%);
  border-radius: 100%;
  padding: 1.6rem;
}

.primary-gradient {
  background: linear-gradient(70deg, #17754c 0%, #edffe3 67%, #fff 100%);
}

/* banner css */

.banner .container {
  height: 100%;
}

.banner .gradient-border {
  padding: 2rem;
}

.banner .border-wrap {
  border: 10px solid;
}

.banner-img img {
  width: 100%;
}

.banner .heading {
  font-size: 3.9rem;
}

.text-orange {
  color: #f6a834;
}

.heading.text-orange {
  font-size: 50px;
  margin-bottom: 35px;
}

.left-wrap {
  white-space: normal !important;
  height: 100%;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
}

.img-wrap {
  width: 60%;
  margin: auto;
}

.common-btn {
  background-color: transparent;
  border: none;
}

.common-btn:hover:active {
  border: none;
}

.tagline {
  width: 95%;
  font-size: 1.65rem;
  line-height: 39px;
}

.top-bar span {
  color: #fff;
  margin-right: 10px;
}

.top-bar li:hover {
  transform: scale(1.01);
}

.social-icons li a:hover {
  animation: pulse 2s infinite;
}

.border-wrap {
  border-radius: 100%;
  border: 7px solid;
  background-color: #fff;
}

.border-green {
  border-color: #e2efda;
}

@keyframes animte {
  0% {
    transform: translate(70px);
    -webkit-transform: translate(70px)
  }

  25% {
    transform: translate(50px);
    -webkit-transform: translate(50px)
  }

  50% {
    transform: translate(-10px);
    -webkit-transform: translate(-10px)
  }

  75% {
    transform: translate(20px);
    -webkit-transform: translate(20px)
  }

  100% {
    transform: translate(50px);
    -webkit-transform: translate(50px)
  }
}


/* start css for services */
.green-bg {
  background-color: #e2efda;
}

.border-wrap .img-fluid {
  display: flex;
  align-content: center;
  justify-content: center;
}

.common-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.img-wrap img {
  border-radius: 100%;
}

.common-wrap .img-wrap {
  width: 50%;
  margin: auto;
}


/* start css for services Area */
.listing {
  margin-bottom: 10px;
}

.poppins-font {
  font-family: "Poppins", sans-serif;
}

.serviceArea .poppins-font {
  font-weight: 600;
  font-size: 1.2rem;
}

.service-left img {
  max-width: 90%;
}

.area-wrapper {
  padding-left: 30px;
}

/* start css for footer */
footer .poppins-font {
  font-weight: 500;
}

b {
  font-weight: 600;
}

footer p,
footer li {
  font-size: 1.03rem;
}

.email-wrap a {
  font-size: 18px;
}

footer svg {
  margin-right: 15px;
}

.footer-wrap {
  width: 80%;
}

footer .gradient-border {
  border-radius: 0;
  padding: 5px;
  margin-left: 0;
}

footer .img-wrap img {
  border-radius: 0;
}

.blank {
  list-style: none;
  color: transparent;
}

.orange-bg {
  background-color: #e4811d;
}
.copyright{
  margin: 5px 0;
}

.copyright img {
  height: 50px;
}

.copyright svg {
  margin-right: 20px;
  font-size: 22px;
}

/* popup css */

.popup-box {
  z-index: 99;
  content: '';
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
}

.popup-wrappper p.tagline {
  font-size: 1rem;
  color: #4b4b4b;
  width: 100%;
  text-align: left;
  font-weight: 500;
  line-height: normal;
}

.popup-wrappper p {
  display: flex;
  align-content: center;
  margin: 0;
  font-size: 1.05rem;
}

.popup-wrappper span {
  margin: auto 10px auto 0px;
  width: 20px;
  height: 20px;
  background-color: #434343;
  fill: #e2efda;
  border-radius: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.popup-wrappper .info-wrap svg {
  margin: auto;
  fill: #e2efda;
  font-size: 10px;
}

.popup-box .row {
  flex: 1;
}

.swal2-container.swal2-center.swal2-backdrop-show .tagline {
  width: 100%;
  font-size: 1.29rem;
  line-height: 35px;
}

.swal2-container .img-outer {
  background-image: url(../src/assets/Fetch-logo.png);
  height: 190px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  width: 300px;
  background-position: center;
}

.popup-wrappper {
  width: 85%;
  margin: auto;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.swal2-popup {
  width: 90% !important;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swal2-actions {
  display: none !important;
}

.box {
  height: 93vh;
  margin: auto;
  width: 80%;
  position: relative;
}

.medium-font {
  font-weight: 500;
}

.box .popup-wrappper .ui.input>input {
  border: 1px solid #434343;
  padding: .375rem 1.25rem;
  width: 100%;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
}


.box .ui.input {
  position: relative;
  width: 90%;
}

.box .close-icon,
.swal2-container.swal2-center.swal2-backdrop-show .swal2-close {
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: #1c7c54;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  font-size: 20px;
}

.swal2-container.swal2-center.swal2-backdrop-show .swal2-close {
    line-height: 1;
    font-size: 37px;
    font-weight: 500;
}

.small-img {
  width: 18%;
}

.scroll {
  position: fixed;
  width: auto;
  left: auto;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  right: 20px;
  color: #f6a834;
  background-color: transparent;
  border: 0;
  display: flex;
}

.scroll svg {
  box-shadow: 0px 3px 8px 3px rgb(0 0 0 / 11%);
  border-radius: 100%;
  background-color: #fff;
}


/* start custom css for tooltip */
.popup-box .__react_component_tooltip {
  background: #f6a834;
  padding: 2px 10px;
  margin-top: 5px !important;
}

.popup-box .__react_component_tooltip.place-bottom::after {
  border-bottom-color: #f6a834;
}

.field {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}


.custom-textarea {
  resize: vertical;
  width: 80%;
  background-color: transparent;
  border: 1px solid #434343;
  padding: 5px 15px;
}

.custom-textarea:focus,
.custom-textarea::focus-visible {
  outline: none;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}

.custom-checkbox input {
  display: none;
  /* Hide the original checkbox */
}

.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  border: 1px solid #434343;
  border-radius: 0;
  margin-right: 10px;
  position: relative;
  background: transparent;
}

.custom-checkbox input:checked+.checkmark {
  background-color: transparent;
  border-color: #434343;
}

.custom-checkbox input:checked+.checkmark::after {
  content: "✔";
  color: #e4811d;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-33%, -55%);
  font-weight: 100;
}

.copyright a {
  color: #fff;
}

form.ui.form h5 {
  text-align: left;
}

@media screen and (max-width:1366px) {
  .banner p {
    font-size: 17px;
  }

  .heading-text {
    font-size: 45px;
  }

  .rect:before {
    width: 53px;
    height: 57px;
  }
}

@media screen and (min-width:992px) {
  button.navbar-toggle {
    display: none;
  }

  .navbar-collapse {
    display: flex !important;
  }
}

@media screen and (max-width:992px) {
  button.navbar-toggler {
    border: 2px solid #1c7c54;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    outline: none;
    box-shadow: none;
    position: relative;
    margin-left: 0;
  }

  .popup-wrappper .info-wrap {
    margin-bottom: 1rem;
  }
  .navbar button.common-btn {
    margin-left: auto;
    display: flex;
    justify-content: end;
    padding:0;
}

  .blank {
    display: none;
  }

  .box .ui.input {
    width: 100%;
  }

  .hamburger {
    fill: #1c7c54;
  }

  .banner .border-wrap {
    border: 5px solid;
  }

  .dropdown-menu .show {
    -webkit-animation: fadeIn 0.3s alternate;
    /* Safari 4.0 - 8.0 */
    animation: fadeIn 0.3s alternate;
  }

  .nav-item.dropdown.dropdown-mega {
    position: static;
  }

  .popup-wrappper {
    width: 100%;
  }

  .custom-checkbox {
    width: 50%;
  }

  .submit svg {
    width: 80%;
  }

  .nav-item.dropdown.dropdown-mega .dropdown-menu {
    width: 90%;
    top: auto;
    left: 5%;
  }

  .navbar-toggler {
    border: none;
    padding: 0;
    outline: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar .container {
    position: relative;
  }

  #navbar-content {
    position: absolute;
    background-color: #1c7c54;
    left: 15px;
    width: 93%;
    z-index: 9;
    padding: 0 15px;
    box-shadow: 1px 6px 9px rgb(0 0 0 / 25%);
    top: 56px;
  }

  .tagline {
    width: 100%;
  }

  .navbar a {
    color: #fff;
  }

  .brand .img-responsive {
    max-height: 55px;
  }

  .brand {
    top: -2px;
  }

  .navbar {
    padding: 5px 0;
  }

  .banner .gradient-border {
    padding: 1.4rem;
  }

  .w-85 svg {
    height: 50px;
  }

  .common-wrap .img-wrap {
    width: 70%;
  }
}

@media screen and (max-width:768px) {
  .banner {
    height: auto;
  }
  .copyright.justify-content-end {
    padding: 10px 0;
    border-top: 1px dotted #fff;
    margin-top: 10px;
}

  .banner .gradient-border {
    padding: 0.9rem;
  }

  .p-80 {
    padding: 50px 0;
  }

  .rect:before {
    width: 43px;
    height: 47px;
  }

  .heading-text {
    font-size: 35px;
  }

  .common-wrap .img-wrap {
    max-width: 250px;
  }

  .box {
    height: 85vh;
    width: 90%;
  }
  .banner p {
        font-size: 16px;
    }
  footer p i {
    font-size: 1rem;  
  }  
  .copyright img {
    height: 40px;
  }
  .copyright.text-white.d-flex.align-items-center {
    margin-top: 5px;
  }
}

@media screen and (max-width:576px) {
  .top-bar .d-flex.align-items-center {
    text-align: center;
  }

  .header_top {
    background: #fff;
    overflow: visible;
  }

  .header_top:before {
    display: none;
  }

  .brand {
    position: static;
  }

  .navbar {
    padding: 10px 0;
  }

  .brand .img-responsive {
    max-height: 39px;
  }
}

/* Total scrollbar */
::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1e0c8;
  /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c7c54;
  /* Color of the handle */
  border-radius: 10px;
  /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c7c54;
  /* Color of the handle on hover */
}
